const toggleNavbarFixed = () => {
  const nav = document.querySelector('#menu');
  const banner = document.querySelector('#banner-business');

  if(window.pageYOffset >= nav.offsetTop) {
    nav.classList.add("nav-fixed");
  }

  if(window.pageYOffset <= banner.offsetTop) {
    nav.classList.remove("nav-fixed");
  };
}

export default toggleNavbarFixed;