import React, { useState } from 'react';
import toggleFontSize from '../helpers/toggleFontSize';

const DropdownSettings = () => {

  const [ increasedFont, setIncreasedFont ] = useState(false);

  return(
    <ul id="dropdown-settings" className="dropdown bg-lighter p-16">
      <li className="menu-option" onClick={ () => { toggleFontSize(increasedFont, setIncreasedFont) }}>
        <i className="fas fa-text-height p-8"></i>
        { (increasedFont) ? "Disminuir letra" : "Aumentar letra" }
      </li>
      <li><i className="fas fa-language p-8"></i>Cambiar idioma</li>
      <li><div id="google_translate_element"></div></li>
    </ul>
  )
}

export default DropdownSettings;