import React from 'react';
import toggleModal from '../helpers/toggleModal';
import MapAgencies from './MapAgencies';

const Agencies = ( { sectionTitle, agencies } ) => {
  return(
    <section id="section-agencies" className="page-section container py-128">
      <h2 className="center label-gradient text-32 mt-32 mb-16">{ sectionTitle }</h2>
      < MapAgencies />
      <div className="columns my-16">
        {agencies.map( (agency, index) => (
          <article key={agency.ag_id} className={`ca${index} card-agency col-sm-12 col-md-6 px-16`}>
            <h3 className="text-green-dark text-center bold text-24 mb-16">{ agency.ag_nombre }</h3>
            <div className="mb-32">
              <p className="my-8"><span className="bold">Teléfono: </span>{ agency.ag_telefono }</p>
              <p className="my-8"><span className="bold">Dirección: </span>{ agency.ag_direccion }</p>
            </div>
            <div className="gallery cursor mb-32" onClick={ () => { toggleModal(`modal-gallery${agency.ag_id}`) } }>
              <div className="gallery-label flex"><span className="text-lighter text-24 p-16">Click para conocer la agencia</span></div>
              { (agency.galeria[0]) ?
                <img src={ agency.galeria[0].ga_imagen } alt={agency.galeria[0].ga_titulo} className="agency-img" />
                : null
              }
            </div>
            <a href={agency.ag_url} target="_blank" rel="noopener noreferrer" className="btn-map btn-primary center">
              <i className="fas fa-map-marker-alt"></i>
              <span className="bold ml-8">Cómo llegar</span>
            </a>
          </article>
        ))}
      </div>
    </section>
  )
}

export default Agencies;