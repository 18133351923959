import React from 'react';
import { Link } from 'react-router-dom';
import DropdownSettings from '../components/DropdownSettings'
import toggleNavbarMenu from '../helpers/toggleNavbarMenu';
import toggleDropdownMenu from '../helpers/toggleDropdownMenu';
import sendTo from '../helpers/sendTo';
import Chat from './Chat';

const Navbar = ( { company, menuOptions, menuButtons } ) => {
  return(
    <nav id="menu" className="bg-lighter text-darker">
      <Chat />
      <div className="menu container flex px-16">
        <Link to="/" className="flex anchor">
          <div className="menu-logo flex">
              <img src={ company.em_logo } alt="logo" />
              <h1 className="ml-16 bold">{ company.em_nombre }</h1>
          </div>
        </Link>
        <ul className="menu-items flex">
          <li>
            <ul className="sub-menu menu-items bg-lighter flex">
              <Link to="/" className="anchor">
                <li className="mi1 menu-item py-8 px-8" onClick={ () => { sendTo("section-services") } }>{ menuOptions[0].me_nombre }</li>
              </Link>
              <Link to="/" className="anchor">
                <li className="mi2 menu-item py-8 px-8" onClick={ () => { sendTo("section-associate") } }>{ menuOptions[1].me_nombre }</li>
              </Link>
              <Link to="/" className="anchor">
                <li className="mi3 menu-item py-8 px-8" onClick={ () => { sendTo("section-agencies") } }>{ menuOptions[2].me_nombre }</li>
              </Link>
              <Link to="/" className="anchor">
                <li className="mi4 menu-item py-8 px-8" onClick={ () => { sendTo("section-information") } }>{ menuOptions[3].me_nombre }</li>
              </Link>
              <Link to="/blog" className="anchor">
                <li className="mi5 menu-button py-8 px-8"><button className="btn-primary-invert">{ menuButtons[0].me_nombre }</button></li>
              </Link>
              {/* <li className="mi6 menu-button py-8 px-8"><button className="btn-primary-invert">{ menuButtons[1].me_nombre }</button></li> */}
          </ul>
          </li>
          <li>
            <ul className="menu-options flex">
              <li className=" py-8 px-16" >
                <i className="menu-option fas fa-cog text-24" onClick={ toggleDropdownMenu }></i>
                <DropdownSettings />
              </li>
              <li className=" icon-bars py-8 px-16" onClick={ toggleNavbarMenu }>
                <i className="menu-option fas fa-bars text-24"></i>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar;