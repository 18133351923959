import React from 'react';
import Agencies from '../components/Agencies';
import Associate from '../components/Associate';
import Divider from '../components/Divider';
import Information from '../components/Information';
import Services from '../components/Services';

const Home = ( { data, setService }) => {
  return(
    <main id="home">
      <Services sectionTitle={ data.menu[0].me_nombre } services={ data.servicios } setService={ setService } />
      <Divider />
      <Associate sectionTitle={ data.menu[1].me_nombre } benefits={ data.asociarme } />
      <Divider />
      <Agencies sectionTitle={ data.menu[2].me_nombre } agencies={ data.agencias } />
      <Divider />
      <Information sectionTitle={ data.menu[3].me_nombre } information={ data.quienesSomos } />
    </main>
  )
}

export default Home;