import Swal from 'sweetalert2';

const isNotEmpty = (query) => {
  if(query === "") {
    return false;
  }
  return true;
}

const isEmail = (email) => {
  if(!/\S+@\S+\.\S+/.test(email)) {
    Swal.fire({
      title: 'Advertencia!',
      text: 'Ingrese un correo válido',
      icon: 'warning',
      confirmButtonText: 'Aceptar'
    })
    return false;
  }
  return true;
}

const isDUI = (dui = "12345678-9") => {
  const regex = new RegExp('^[0-9]{8}-[0-9]$');
  if(!regex.test(dui)) {
    Swal.fire({
      title: 'Advertencia!',
      text: 'Ingrese un DUI válido (recuerde incluir guión "-")',
      icon: 'warning',
      confirmButtonText: 'Aceptar'
    })
    return false;
  }
  return true;
}

const isTelephone = (tel) => {
  const regex = new RegExp('^[267][0-9]{7}$');
  if(!regex.test(tel)) {
    Swal.fire({
      title: 'Advertencia!',
      text: 'Ingrese un teléfono válido (sin espacios ni guiones)',
      icon: 'warning',
      confirmButtonText: 'Aceptar'
    })
    return false;
  }
  return true;
}

const isOlder = (age, min = 18, max = 99) => {
  if(age >= min && age <= max) {
    return true;
  }
  Swal.fire({
    title: 'Advertencia!',
    text: `Ingrese una edad entre ${min} - ${max} años`,
    icon: 'warning',
    confirmButtonText: 'Aceptar'
  })
  return false;
}

export { isNotEmpty, isEmail, isDUI, isTelephone, isOlder }