import React from 'react';

const Information = ( { sectionTitle, information } ) => {
  const description = information[0];
  const mision = information[1];
  const vision = information[2];
  const values = information[3];
  const url = information[4].it_descripcion;


  return(
    <section id="section-information" className="page-section container py-128">
      <h2 className="center label-gradient text-32">{ sectionTitle }</h2>
      <div className="cards-info columns">
        <article className="ci-description card-info col-sm-12">
        <h3 className="text-green-dark text-center bold text-24 m-16">{ description.it_titulo }</h3>
        <p className="text-justify">{ description.it_descripcion }</p>
        </article>
        <article className="ci-mision card-info col-sm-12 col-lg-4">
          <h3 className="text-green-dark text-center bold text-24 mb-16">{ mision.it_titulo }</h3>
          <p className="text-justify">{ mision.it_descripcion }</p>
        </article>
        <article className="ci-values card-info col-sm-12 col-lg-4">
          <h3 className="text-green-dark text-center bold text-24 mb-16">{ values.it_titulo }</h3>
          <ul className="information-values">
            {values.valores.map( value => (
              <li key={value.de_id}>{value.de_titulo}</li>
            ))}
          </ul>
        </article>
        <article className="ci-vision card-info col-sm-12 col-lg-4">
          <h3 className="text-green-dark text-center bold text-24 mb-16">{ vision.it_titulo }</h3>
          <p className="text-justify">{ vision.it_descripcion }</p>
        </article>
        <article className="ci-trajectory card-info col-sm-12 text-center ">
          <h3 className="text-green-dark bold text-24 mb-16">Trayectoria</h3>
          <iframe title="Trayectoria" className="lifetime-video" src={ url } frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </article>
      </div>
    </section>
  )
}

export default Information;