import React from 'react';
import toggleModal from '../helpers/toggleModal';
import FormBanner from './FormBanner';

const ModalBannerForm = ( { bannerTitle } ) => {
  return(
    <article id="modal-banner-form" className="modal modal-hide">
      <div className="container modal-box columns p-32">
        <div className="modal-btn-close col-sm-12">
          <i className="fas fa-times" onClick={ () => { toggleModal('modal-banner-form') } }></i>
        </div>
        <FormBanner bannerTitle={ bannerTitle } />
      </div>
    </article>
  )
}

export default ModalBannerForm;