import React, { useState } from 'react';

const Chat = () => {

  const [ scroll, setScroll ] = useState(0);
  window.addEventListener('scroll', () => setScroll(window.pageYOffset))

  const toTheTop = () => {
    document.documentElement.scrollTop = 0;
  }

  return(
    <section id="section-chat">
      <div id="main-buttons">
        {(scroll > 500) ? <button className="btn-chat transparent" onClick={ toTheTop }><i className="fas fa-angle-up"></i></button> : null }
        <a href="https://api.whatsapp.com/send?phone=50376819986" target="_blanck">
          <button className="btn-chat transparent"><i className="fab fa-whatsapp"></i></button>
        </a>
        <a href="https://m.me/ACOACCdeR.L.sv" target="_blanck">
          <button id="fb-mobile" className="btn-chat transparent"><i className="fab fa-facebook-messenger"></i></button>
        </a>
        <div id="fb-root" className="transparent"></div>
      </div>
    </section>
  )
}

export default Chat;