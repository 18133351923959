const postData = async (URL, data) => {
  const KEY = 'YWNjb2FjYzoyMDIw';
  const res = await fetch( URL , {
    method: 'POST',
    headers: {'Authorization': KEY, "Content-Type": "application/json"},
    body: JSON.stringify({ datos: data})
  })
  return res.json();
}

export default postData;