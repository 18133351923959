const collapseService = (idDiv, action) => {
    const element = document.querySelector(`#${idDiv}`);
    if(!action) {
        element.classList.toggle('section-collapse');
    } else {
        if(action === 'open') element.classList.remove('section-collapse');
        else element.classList.add('section-collapse');
    }
}

export default collapseService;