import React, {useContext} from 'react';
import { PageContext } from '../helpers/pageContext';
import collapseService from '../helpers/collapseService';
import sendTo from '../helpers/sendTo';
import toggleModal from '../helpers/toggleModal';

const ModalServices = ( { services, getService } ) => {
  const { setIsFormAssociate } = useContext(PageContext);
  const credits = services[0];
  const savings = services[1];
  const remittances = services[2];
  let serviceTitle = '';
  let serviceDescription = '';

  const collapseAllServices = (serviceSection) => {
    collapseService('service-menu-credits', 'close');
    collapseService('service-menu-savings', 'close');
    collapseService('service-menu-remittances', 'close');
    if(serviceSection) collapseService(serviceSection, 'open');
  }

  const serviceDetail = (type, id) => {
    let service = null;
    if(type === 'credit') {
      collapseAllServices('service-menu-credits');
      service = credits.detalle_modal.find(credit => credit.de_id === id);
      if(!service) service = credits.detalle_modal[0];
    } else if(type === 'saving') {
      collapseAllServices('service-menu-savings');
      service = savings.detalle_modal.find(saving => saving.de_id === id);
      if(!service) service = savings.detalle_modal[0];
    } else if(type === 'remittance') {
      collapseAllServices('service-menu-remittances');
      service = remittances.detalle_modal.find(remittance => remittance.de_id === id);
      if(!service) service = remittances.detalle_modal[0];
    }

    document.querySelector('#serviceTitle').innerHTML = service.de_titulo;
    document.querySelector('#serviceDescription').innerHTML = service.de_detalle;
    sendTo("serviceTitle");
  }

  setTimeout(() => { serviceDetail(getService) }, 100)

  return(
    <article id="modal-services" className="modal modal-hide">
      <div className="modal-services-desktop container modal-box columns">
        <div className="modal-services-menu col-sm-12 col-md-4 nogutter bg-yellow-light">
          <div className="modal-btn-close btn-close-sm pt-16 pr-16">
            <i className="fas fa-times" onClick={ () => { toggleModal('modal-services') } }></i>
          </div>
          <div className="pt-32">
            <div className="service-category-title p-16 flex" onClick={ () => { collapseService('service-menu-credits') } }>
              <h3 className="text-24">{ credits.it_titulo }</h3>
              <i className="fas fa-chevron-down p-8"></i>
            </div>
            <ul id='service-menu-credits' className="service-category-items section-collapse p-16">
              {credits.detalle_modal.map( (credit) => (
                <li key={ credit.de_id }
                  className="service-category-item my-8"
                  onClick={ () => { serviceDetail('credit', credit.de_id) } }>{ credit.de_titulo }</li>
              ))}
            </ul>
          </div>
          <div>
            <div className="service-category-title p-16 flex" onClick={ () => { collapseService('service-menu-savings') } }>
              <h3 className="text-24">{ savings.it_titulo }</h3>
              <i className="fas fa-chevron-down p-8"></i>
            </div>
            <ul id='service-menu-savings' className="service-category-items section-collapse p-16">
              {savings.detalle_modal.map( (saving) => (
                <li key={ saving.de_id }
                className="service-category-item my-8"
                onClick={ () => { serviceDetail('saving', saving.de_id) } }>{ saving.de_titulo }</li>
              ))}
            </ul>
          </div>
          <div className="pb-32">
            <div className="service-category-title p-16 flex" onClick={ () => { collapseService('service-menu-remittances') } }>
              <h3 className="text-24">{ remittances.it_titulo }</h3>
              <i className="fas fa-chevron-down p-8"></i>
            </div>
            <ul id='service-menu-remittances' className="service-category-items section-collapse p-16">
              {remittances.detalle_modal.map( (remittance) => (
                <li key={ remittance.de_id }
                className="service-category-item my-8"
                onClick={ () => { serviceDetail('remittance', remittance.de_id) } }>{ remittance.de_titulo }</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="modal-btn-close btn-close-md">
            <i className="fas fa-times" onClick={ () => { toggleModal('modal-services') } }></i>
          </div>
          <div className="modal-services-body p-16">
            <h2 id='serviceTitle' className="text-green-dark text-24 bold center">{ serviceTitle }</h2>
            <div id='serviceDescription' className="serviceDescription my-24" dangerouslySetInnerHTML={{ __html: serviceDescription }} />
            <button className="btn-primary center mb-16" onClick={ () => {
                setIsFormAssociate(false);
                toggleModal('modal-services');
                toggleModal('modal-reservation');
              }}>
              <i className="fas fa-paper-plane"></i><span className="bold ml-8">Quiero aplicar</span>
            </button>
          </div>
        </div>
      </div>
    </article>
  )
}

export default ModalServices;