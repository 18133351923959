import React from 'react';
import { useLocation } from 'react-router-dom';
import Banner from '../components/Banner';
import Navbar from '../components/Navbar';
import hideBanner from '../helpers/hideBanner';
import toggleNavbarFixed from '../helpers/toggleNavbarFixed';

const Header = ( { company, menu, advertisingBanner, businessBanner } ) => {
  const location = useLocation();
  hideBanner(location.pathname);

  let menuOptions = [menu[0], menu[1], menu[2], menu[3]];
  let menuButtons = [menu[4], menu[5]];

  window.addEventListener('scroll', () => { toggleNavbarFixed() })
  return(
    <header>
      { (!parseInt(advertisingBanner.ba_estado)) ? <Banner advertisingBanner={ advertisingBanner } /> : null }
      <Navbar company={ company } menuOptions={ menuOptions } menuButtons={ menuButtons }/>
      <section id='banner-business' style={{ backgroundImage: `url(${businessBanner.ba_banner})` }}>
        <div className="banner-filter"></div>
        <h2 className="banner-slogan flex text-32 text-lighter p-32 text-center">{ company.em_eslogan }</h2>
      </section>
    </header>
  )
}

export default Header;