import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PageContext } from '../helpers/pageContext';
import useFetchData from '../hooks/useFetchData';
import Layout from './Layout';
import Home from './Home';
import Blog from './Blog';
import NotFound from '../components/NotFound';

const App = () => {
  const { data, loading } = useFetchData();
  const [isFormAssociate, setIsFormAssociate] = useState(false);
  const [ showService, setShowService ] = useState('credit');

  console.log(process.env.REACT_APP_LABEL);

  return (
    <BrowserRouter>
      <PageContext.Provider value={{isFormAssociate, setIsFormAssociate}}>
        <Layout data={ data } loading={ loading } getService={ showService } setService={ setShowService }>
          <Switch>
            <Route exact path='/' render={ () => <Home data={ data } setService={ setShowService } /> } />
            <Route exact path='/index.html' render={ () => <Home data={ data } setService={ setShowService } /> } />
            <Route exact path='/informacion' render={ () => <Home data={ data } setService={ setShowService } /> } />
            <Route exact path='/blog' component={ Blog } />
            <Route component={ NotFound } />
          </Switch>
        </Layout>
      </PageContext.Provider>
    </BrowserRouter>
  )
}

export default App;