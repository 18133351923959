import React from 'react';
import toggleModal from '../helpers/toggleModal';

const Banner = ( { advertisingBanner } ) => {
  return(
    <section id='banner-advertising' className="bg-dark py-24">
      <article className="banner-advertising container columns bg-lighter" >
        <div className="col-sm-12 col-md-2 col-lg-2">
          <img src={ advertisingBanner.ba_banner } alt="regalo" className="banner-image" />
        </div>
        <div className="col-sm-12 col-md-8">
          <h2 className="banner-advertising-title label-gradient text-24 bold mb-16 py-8 px-16">{ advertisingBanner.ba_titulo }</h2>
          <div dangerouslySetInnerHTML={{ __html: advertisingBanner.ba_descripcion }} />
        </div>
        <div className="banner-button col-sm-12 col-md-2 col-lg-2">
          <button className="btn-primary" onClick={ () => { toggleModal('modal-banner-form') } }>
            <i className="fas fa-thumbs-up"></i>
            <span className="bold ml-8">Me interesa</span>
          </button>
        </div>
      </article>
    </section>
  )
}

export default Banner;