import React, {useContext} from 'react';
import { PageContext } from '../helpers/pageContext';
import toggleModal from '../helpers/toggleModal';
import illustration1 from '../assets/img/illustrations/illustration1.svg';
import illustration2 from '../assets/img/illustrations/illustration2.svg';
import illustration3 from '../assets/img/illustrations/illustration3.svg';


const Associate = ( { sectionTitle, benefits } ) => {
  const { setIsFormAssociate } = useContext(PageContext);
  return(
    <section id="section-associate" className="page-section container py-128">
      <h2 className="center label-gradient text-32">{ sectionTitle }</h2>
      <article className="card-associate columns mt-16">
          <h3 className="text-center col-sm-12 text-green-dark bold text-24">{ benefits[0].it_titulo }</h3>
          <ul className="benefit1 associate-list flex col-sm-12 col-md-6">
            { benefits[0].detalles.map( benefit => (<li key={ benefit.de_id } className="py-4">{ benefit.de_detalle }</li> ))}
          </ul>
          <div className="bi1 benefit-img col-sm-12 col-md-6 flex">
            <img src={illustration1} alt="Ilustracion" />
          </div>
      </article>
      <article className="card-associate columns mt-16">
          <h3 className="text-center col-sm-12 text-green-dark bold text-24">{ benefits[1].it_titulo }</h3>
          <div className="bi2 benefit-img col-sm-12 col-md-6 flex">
            <img src={illustration2} alt="Ilustracion" />
          </div>
          <ul className="benefit2 associate-list flex col-sm-12 col-md-6">
            { benefits[1].detalles.map( benefit => (<li key={ benefit.de_id } className="py-4">{ benefit.de_detalle }</li> ))}
          </ul>
      </article>
      <article className="card-associate columns mt-16">
        <h3 className="text-center col-sm-12 text-green-dark bold text-24">{ benefits[2].it_titulo }</h3>
        <div className="benefit3 associate-center flex col-sm-12 col-md-6">
          <ul className="associate-list">
            { benefits[2].detalles.map( benefit => (<li key={ benefit.de_id } className="py-4">{ benefit.de_detalle }</li> ))}
          </ul>
          <button className="btn-primary mt-16" onClick={ () => {
              setIsFormAssociate(true);
              toggleModal('modal-reservation');
            } }>
            <i className="fas fa-id-card"></i><span className="bold ml-8 text-16">Quiero asociarme</span>
          </button>
        </div>
        <div className="bi3 benefit-img col-sm-12 col-md-6 flex">
          <img src={illustration3} alt="Ilustracion" />
        </div>
      </article>
    </section>
  )
}

export default Associate;