import React from 'react';
import toggleModal from '../helpers/toggleModal';
import FormAssociate from './FormAssociate';

const ModalApplicationForm = ({ services, activities }) => {
  return(
    <article id="modal-reservation" className="modal modal-hide">
      <div className="container modal-box columns p-32">
        <div className="modal-btn-close col-sm-12">
          <i className="fas fa-times" onClick={ () => { toggleModal('modal-reservation') } }></i>
        </div>
        <FormAssociate services={ services } activities={ activities }/>
      </div>
    </article>
  )
}

export default ModalApplicationForm;