import { useEffect, useState } from "react";
import env from "../config";
import getData from "../helpers/getData";

const useFetchData = (URL = `${env.baseUrl}/contenido`) => {
  const temp = {
    "empresa": {
        "em_id": "1",
        "em_nombre": "ACOACC",
        "em_eslogan": "La Cooperativa Financiera que piensa en ti, impulsando el desarrollo",
        "em_logo": ""
    },
    "banner_promocional": {
        "ba_id": "1",
        "ba_banner": "",
        "ba_tipo": "0",
        "ba_titulo": "",
        "ba_descripcion": "",
        "ba_estado": "0"
    },
    "menu": [
        {
            "me_id": "1",
            "me_nombre": "Servicios"
        },
        {
            "me_id": "2",
            "me_nombre": "Asociarse"
        },
        {
            "me_id": "3",
            "me_nombre": "Agencias"
        },
        {
            "me_id": "4",
            "me_nombre": "Quiénes somos"
        },
        {
            "me_id": "5",
            "me_nombre": "Blog"
        },
        {
            "me_id": "6",
            "me_nombre": "Banca en línea"
        }
    ],
    "banner_empresarial": {
        "ba_banner": "",
        "ba_tipo": "1",
        "ba_estado": "0"
    }
  };

  const [ state, setState ] = useState({
    data: temp,
    loading: true
  });

  useEffect( () => {
    getData(URL)
      .then( info => {
        setState({
          data: info,
          loading: false
        })
      })
  }, [URL])
  return state;
}

export default useFetchData;