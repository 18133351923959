import React from 'react';
import ReactDOM from 'react-dom'
import Loading from '../components/Loading';
import Header from './Header';
import Modals from './Modals';
import Footer from '../components/Footer';

const Layout = ( { children, data, loading, getService, setService } ) => {
  return(
    <>
      { (loading) ? ReactDOM.createPortal(<Loading />, document.querySelector('#modal-loader')) : null }
      <Header company={data.empresa} menu={data.menu} advertisingBanner={data.banner_promocional} businessBanner={data.banner_empresarial} />
      { (!loading) ? children : null }
      { (!loading) ? <Modals services={ data.servicios } getService={ getService } activities={ data.actividades } bannerTitle={ data.banner_promocional.ba_titulo } agencies={ data.agencias } /> : null}
      { (!loading) ? <Footer company={data.empresa} services={ data.servicios } agencies={ data.agencias } setService={ setService } social={ data.redes } /> : null }
    </>
  )
}

export default Layout;
