import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const animations = () => {
  if(!document.querySelector('#home')){
    return false;
  }

  gsap.registerPlugin(ScrollTrigger);

  // Animations for Advertising Banner
  gsap.from('.banner-advertising', {duration: 1, opacity: 0});
  gsap.from('.banner-advertising-title', {duration: 1, x: 200});

  // Animations for Navbar and Business Banner
  const tlBusinessBanner = gsap.timeline();
  tlBusinessBanner
    .from(".mi1", {duration: 0.3, y: 30, opacity: 0, ease: "power3.out"})
    .from(".mi2", {duration: 0.3, y: 30, opacity: 0, ease: "power3.out"})
    .from(".mi3", {duration: 0.3, y: 30, opacity: 0, ease: "power3.out"})
    .from(".mi4", {duration: 0.3, y: 30, opacity: 0, ease: "power3.out"})
    .from(".mi5", {duration: 0.3, y: 30, opacity: 0, ease: "power3.out"})
    .from(".mi6", {duration: 0.3, y: 30, opacity: 0, ease: "power3.out"})
  gsap.from(".banner-slogan", {
    scrollTrigger: {
      trigger: ".banner-slogan", toggleActions: "restart none restart none", start: "top center", end: "bottom center"
    },
    duration: 1, y: 30, opacity: 0, ease: "power3.out"})

  // Animations for Services
  const tlServiceCards = gsap.timeline({scrollTrigger: {
    trigger: ".cs0", toggleActions: "restart none restart none", start: "top center", end: "bottom center"
  }});
  tlServiceCards
    .from(".cs0", {duration: 0.5, opacity: 0, x: 300, ease: "power3.out"})
    .from(".cs1", {duration: 0.5, opacity: 0, x: 300, ease: "power3.out"})
    .from(".cs2", {duration: 0.5, opacity: 0, x: 300, ease: "power3.out"})

  // Animations for Associate
  gsap.from('.bi1', {
    scrollTrigger: {
      trigger: ".bi1", toggleActions: "restart none restart none", start: "top center", end: "bottom center"
    },
    duration: 1, x: 300, opacity: 0});
  gsap.from('.benefit1', {
    scrollTrigger: {
      trigger: ".benefit1", toggleActions: "restart none restart none", start: "top center", end: "bottom center"
    },
    duration: 1, x: -300, opacity: 0});
  gsap.from('.bi2', {
    scrollTrigger: {
      trigger: ".bi2", toggleActions: "restart none restart none", start: "top center", end: "bottom center"
    },
    duration: 1, x: -300, opacity: 0});
  gsap.from('.benefit2', {
    scrollTrigger: {
      trigger: ".benefit2", toggleActions: "restart none restart none", start: "top center", end: "bottom center"
    },
    duration: 1, x: 300, opacity: 0});
  gsap.from('.bi3', {
    scrollTrigger: {
      trigger: ".bi3", toggleActions: "restart none restart none", start: "top center", end: "bottom center"
    },
    duration: 1, x: 300, opacity: 0});
  gsap.from('.benefit3', {
    scrollTrigger: {
      trigger: ".benefit3", toggleActions: "restart none restart none", start: "top center", end: "bottom center"
    },
    duration: 1, x: -300, opacity: 0});

  // Animations for Agencies
  gsap.from('.map', {
    scrollTrigger: {
      trigger: ".map", toggleActions: "restart none restart none", start: "top center", end: "bottom center"
    },
    duration: 1, y: 100, opacity: 0});
  gsap.from('.ca0', {
    scrollTrigger: {
      trigger: ".ca0", toggleActions: "restart none restart none", start: "top center", end: "bottom center"
    },
    duration: 2, x: -300, opacity: 0});
  gsap.from('.ca1', {scrollTrigger: {
    trigger: ".ca1", toggleActions: "restart none restart none", start: "top center", end: "bottom center"
  },
  duration: 2, x: 300, opacity: 0});

  // Animations for About
  const tlAbout = gsap.timeline({scrollTrigger: {
    trigger: ".ci-description", toggleActions: "restart none restart none", start: "top center", end: "bottom center"
  }});
  tlAbout
    .from('.ci-description', {duration: 0.5, y: 100, opacity: 0})
    .from('.ci-mision', {duration: 0.5, y: 100, opacity: 0})
    .from('.ci-values', {duration: 0.5, y: 100, opacity: 0})
    .from('.ci-vision', {duration: 0.5, y: 100, opacity: 0})
    .from('.ci-trajectory', {duration: 0.5, y: 100, opacity: 0})
}

export default animations;