import React from 'react';

const Loading = () => {
  const Logo = 'https://acoacc.com/acoacc-media/logo.svg';

  return(
    <div className="loading">
      <img src={Logo} className="loading-logo" width="200" alt="logo" />
      <h2 className="mt-24 text-24">Cargando ...</h2>
    </div>
  )
}

export default Loading;