import React from 'react';

const Post = ( { title, URL } ) => {
  return(
    <div className="post-center">
      <iframe
        title={ title }
        src={  `https://www.facebook.com/plugins/post.php?href=${ encodeURIComponent(URL) }&width=350&show_text=true&height=580` }
        width="350"
        height="580"
        style={ {border:"none", overflow:"hidden"} }
        scrolling="no"
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
      </iframe>
    </div>
  )
}

export default Post;