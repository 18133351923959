import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import env from '../config';
import useFetchData from '../hooks/useFetchData';
import getData from '../helpers/getData';
import formatDate from '../helpers/formatDate';
import Loading from '../components/Loading';
import Post from '../components/Post';
import Swal from 'sweetalert2';

const Blog = () => {
  const [ state, setState ] = useState(null);

  const { data:started, loading:loadingStarted } = useFetchData(`${env.baseUrl}/destacados`);
  const { data:blog, loading:loadingBlog } = useFetchData(`${env.baseUrl}/publicaciones`);

  const search = (e, page = (state.page + 1)) => {
    e.preventDefault();
    let query = `?page=${page}&titulo=${document.querySelector('#txtSearch').value}`;
    switch (document.querySelector('#slcFilter').value) {
      case 'desc':
        query += "&fecha=1";
        break;
        case 'asce':
        query += "&fecha=0";
        break;
      case 'star':
        query += "&destacado=0";
        break;
      case 'nsta':
        query += "&destacado=1";
        break;
      default:
        break;
    }

    getData(`${env.baseUrl}/publicaciones${query}`)
      .then( res => {
        if( page !== 1 ) {
          setState({
            ...state,
            page: state.page + 1,
            blog: {
              pagination: res.pagination,
              publicaciones: [].concat(state.blog.publicaciones, res.publicaciones)
            }
          })
        } else {
          if(res.status) {
            setState({
              ...state,
              page: 1,
              blog: {
                pagination: {},
                publicaciones: []
              }
            })
          } else {
            setState({
              ...state,
              page: 1,
              blog: res
            })
          }
        }
      })
      .catch( error => {
        Swal.fire({
          title: '¡Error!',
          text: 'No se pudo completar la búsqueda, intente nuevamente',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        })
      })
  }

  if(loadingBlog || loadingStarted) {
    return ReactDOM.createPortal(<Loading />, document.querySelector('#modal-loader'));
  }

  if(!state) {
    setState({
      blog,
      started,
      page: 1
    })
  }

  if(state?.blog.status?.message === "No hay registros") {
    return(
      <div className="my-64 mx-32">
        <h2 className="text-32 bold">Aún no hay publicaciones para mostrar</h2>
      </div>
    )
  }

  return(
    <main className="columns">
      {(state?.started.status?.message !== "No hay registros") ?
        <aside className="col-sm-12">
          <div className="bg-green-light text-24 text-center bold p-8 "> Publicaciones destacadas</div>
          <section className="started-post">
            {state?.started.map( post => (
              <article key={ post.pu_id } className="px-16 pt-16" >
                <a href={ post.pu_url } target="_blank" rel="noopener noreferrer" className="anchor">
                  <h2 className="bg-green-light mb-16 py-8 px-16">{ post.pu_titulo } ({ formatDate(post.pu_fecha) })</h2>
                </a>
                <Post title={ post.pu_titulo } URL={ post.pu_url } />
              </article>
            ))}
          </section>
      </aside>
      : null}
      <section className="col-sm-12">
        <form className="columns bg-green-light px-16">
          <div className="input-search mt-8 mr-24">
            <span className="bold mr-8">Buscar </span>
            <input type="text" id="txtSearch" placeholder="Título"/>
          </div>
          <div className="input-search mt-8 mr-24">
            <span className="bold mr-8">Ordenar por </span>
            <select id="slcFilter" onChange={ e => { search(e, 1) } }>
              <option value="desc"> Fecha (descendente) </option>
              <option value="asce"> Fecha (ascendente) </option>
              <option value="star"> Destacados</option>
              <option value="nsta"> No destacados</option>
            </select>
          </div>
          <button className="btn-primary-invert my-8" onClick={ e => { search(e, 1) } }>
            <i className="fas fa-search"></i>
            <span className="bold ml-8">Buscar</span>
          </button>
        </form>
        <section className="columns">
          { (state?.blog.publicaciones.length) ? null : <h2 className="m-24 text-24 bold">No se encontraron publicaciones con ese título.</h2> }
          {state?.blog.publicaciones.map( post => (
            <article key={ post.pu_id } className="col-sm-12 col-md-6 col-lg-4">
              <a href={ post.pu_url } target="_blank" rel="noopener noreferrer" className="anchor">
                <h2 className="bg-green-light mb-16 py-8 px-16">
                <span className="bold">{ post.pu_titulo } </span>
                ({ formatDate(post.pu_fecha) })
                </h2>
              </a>
              <Post title={ post.pu_titulo } URL={ post.pu_url } />
            </article>
          ))}
        </section>
        { (state?.blog.pagination.pagina !== state?.blog.pagination.totalPaginas) ?
          <button className="btn-primary-invert btn-load-more" onClick={ search }>
            <i className="fas fa-plus-circle"></i>
            <span className="bold ml-8">Cargar más publicaciones</span>
          </button> : null }
      </section>
    </main>
  )
}

export default Blog;