const getData = async (URL) => {
  const KEY = 'YWNjb2FjYzoyMDIw';
  const res = await fetch(URL, {
    method:'GET',
    headers: {'Authorization': KEY}
  });
  const data = await res.json();
  return data;
}

export default getData;