import Swal from 'sweetalert2';
import env from '../config';
import { isNotEmpty, isEmail, isDUI, isOlder, isTelephone } from './validator';
import disableButtonState from './disableButtonState';
import postData from './postData'

const validateFormAssociate = async (form, btn) => {
  const formIsNotEmpty = Object.values(form).every( field => isNotEmpty(field) );

  if(formIsNotEmpty) {
    if(!isDUI(form.cl_dui)) {
      return false;
    }

    if(!isTelephone(form.cl_telefono)) {
      return false;
    }

    if(!isEmail(form.cl_correo)) {
      return false;
    }

    if(!isOlder(form.cl_edad, 25, 55)) {
      return false;
    }

    disableButtonState(btn, true, 'Enviando...', true);

    const flag = await postData(`${env.baseUrl}/post_solicitudes`, form)
    .then( res => {
      if(res.status.type === 'error') {
        throw new Error( res.status );
      }

      Swal.fire({
        title: '¡Felicidades!',
        text: 'Has completado el registro exitosamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      })
      disableButtonState(btn, false, 'Enviar', true);
      return true;
    })
    .catch( e => {
      Swal.fire({
        title: '¡Error!',
        text: 'No se pudo completar el registro, intente nuevamente',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      })
      disableButtonState(btn, false, 'Enviar', true);
      return false;
    })

    return flag;
  } else {
    Swal.fire({
      title: '¡Advertencia!',
      text: 'Formulario Incompleto',
      icon: 'warning',
      confirmButtonText: 'Aceptar'
    })
    return false;
  }
}

export default validateFormAssociate;