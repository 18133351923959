const hideBanner = (path) => {
  const banner = document.querySelector('#banner-business');
  if(banner) {
    if( path.includes("/blog") ) {
      banner.style.display = "none";
    } else {
      banner.style.display = "block";
    }
  }
}

export default hideBanner;