const showActiveSection = () => {
  const items = document.querySelectorAll('.menu-item');
  const sections = document.querySelectorAll('.page-section');
  const disableActives = () => {
    items.forEach( item => {
      item.classList.remove('menu-item-active');
    });
  }

  if(sections.length) {
    if(window.scrollY >= (sections[0].offsetTop - 200) && window.scrollY < (sections[1].offsetTop - 200)) {
      disableActives();
      items[0].classList.add('menu-item-active');
    } else if(window.scrollY >= (sections[1].offsetTop - 200) && window.scrollY < (sections[2].offsetTop - 200)) {
      disableActives();
      items[1].classList.add('menu-item-active');
    } else if(window.scrollY >= (sections[2].offsetTop - 200) && window.scrollY < (sections[3].offsetTop - 200)) {
      disableActives();
      items[2].classList.add('menu-item-active');
    } else if(window.scrollY >= (sections[3].offsetTop - 200) && window.scrollY < (sections[3].offsetTop + sections[3].offsetHeight)) {
      disableActives();
      items[3].classList.add('menu-item-active');
    }
  }

}

export default showActiveSection;
