import React from 'react';
import animations from '../helpers/animations';
import showActiveSection from '../helpers/showActiveSection';
import toggleModal from '../helpers/toggleModal';

const Footer = ( { company, services, agencies, setService, social } ) => {
  const mediaQuery = window.matchMedia('(min-width: 1025px)')
  if (mediaQuery.matches) setTimeout( animations, 100 );

  window.addEventListener('scroll', () => { showActiveSection() });

  const servicesList = ['credit','saving','remittance'];
  const showModalService = (service) => {
    setService(service);
    toggleModal('modal-services');
  }

  return(
    <footer className="bg-dark text-lighter p-32">
      <section className="container columns">
        <div className="footer-logo flex col-sm-12 col-lg-3">
          <img src={ company.em_logo } alt="logo" />
          <h2 className="text-24 ml-16 bold">{ company.em_nombre }</h2>
        </div>
        <ul className="col-sm-6 col-md-4 col-lg-3 text-center">
          <li><h3 className="text-24 bold mb-24">Servicios</h3></li>
          {services.map( service => (
            <li key={ service.it_id } className="mb-16" onClick={ () => { showModalService(servicesList[service.it_orden - 1]) } }>
              <h4 className="cursor thin">{ service.it_titulo }</h4>
            </li>
          ))}
        </ul>
        <ul className="col-sm-6 col-md-4 col-lg-3 text-center">
          <li className="mb-4"><h3 className="text-24 bold mb-24">Contáctanos</h3></li>
          <li className="mb-16 columns">
            <h3 className="bold nogutter col-sm-12">{ agencies[0].ag_nombre }</h3>
            <h4 className="thin nogutter my-8 col-sm-12">{ agencies[0].ag_telefono }</h4>
          </li>
          <li className="columns">
            <h3 className="bold nogutter col-sm-12">{ agencies[1].ag_nombre }</h3>
            <h4 className="thin nogutter my-8 col-sm-12">{ agencies[1].ag_telefono }</h4>
          </li>
        </ul>
        <ul className="columns col-sm-12 col-md-4 col-lg-3 text-center">
          <li className="col-sm-12 nogutter mb-4"><h3 className="text-24 bold mb-8">Redes sociales</h3></li>
          <li className="cursor col-sm-6 col-md-12 nogutter mb-4">
            <h4><i className="fab fa-facebook-f"></i> <a href={ social[0].it_descripcion } target="_blank" rel="noopener noreferrer">Facebook</a></h4>
          </li>
          <li className="cursor col-sm-6 col-md-12 nogutter mb-4">
            <h4><i className="fab fa-instagram"></i> <a href={ social[1].it_descripcion } target="_blank" rel="noopener noreferrer">Instagram</a></h4>
          </li>
          <li className="cursor col-sm-6 col-md-12 nogutter">
            <h4><i className="fab fa-twitter"></i> <a href={ social[2].it_descripcion } target="_blank" rel="noopener noreferrer">Twitter</a></h4>
          </li>
          <li className="cursor col-sm-6 col-md-12 nogutter">
            <h4><i className="fab fa-youtube"></i> <a href={ social[3].it_descripcion } target="_blank" rel="noopener noreferrer">YouTube</a></h4>
          </li>
        </ul>
      </section>
    </footer>
  )
}

export default Footer;