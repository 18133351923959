import React from 'react';

const MapAgencies = () => {
  return(
    <div id="map-sv" className="map my-32">
        <iframe title="map-agencies" src="https://www.google.com/maps/d/u/0/embed?mid=16USNnKmHx8aS3TgGfeg3vCdWxUsK9XHU&amp;z=8"></iframe>
    </div>
  )
}

export default MapAgencies;