import React from 'react';
import credits from '../assets/img/icon/credit.svg';
import savings from '../assets/img/icon/saving.svg';
import remittances from '../assets/img/icon/remittance.svg';
import toggleModal from '../helpers/toggleModal';

const Services = ( { sectionTitle, services, setService } ) => {
  const servicesIcons = [credits, savings, remittances];
  const servicesList = ['credit','saving','remittance'];
  const showModalService = (service) => {
    setService(service);
    toggleModal('modal-services');
  }

  return(
    <section id='section-services' className="page-section container py-128">
      <h2 className="center label-gradient text-32">{ sectionTitle }</h2>
      <div id="cards-service" className="columns my-16">
        {services.map( (service, index) => (
          <div key={ service.it_id } className="col-sm-12 col-md-4">
          <article className={`cs${index} card-service p-32`} onClick={ () => { showModalService(servicesList[index]) } }>
            <h3 className="text-green-dark text-center bold text-24">{ service.it_titulo }</h3>
            <img className="my-32" src={servicesIcons[index]} alt="icon" />
            <p className="service-description">{ service.it_descripcion }</p>
            <button className="btn-primary mt-16">
              <i className="fas fa-search-dollar"></i>
              <span className="bold ml-8">Ver detalles</span>
            </button>
          </article>
        </div>
        ))}
      </div>
    </section>
  )
}

export default Services;