import React from 'react';
import toggleModal from '../helpers/toggleModal';

const ModalGallery = ( { agencies } ) => {

  const onGalleryChange = e => {
    const photoId = e.target.getAttribute('data-id');
    const agencyIndex = e.target.getAttribute('data-index');
    const photo = agencies[agencyIndex].galeria.find( photo => photo.ga_id === photoId);

    document.querySelector(`#urlPhoto${photo.fk_ga_ag}`).src = photo.ga_imagen;
    document.querySelector(`#titlePhoto${photo.fk_ga_ag}`).innerHTML = photo.ga_titulo;
    document.querySelector(`#descriptionPhoto${photo.fk_ga_ag}`).innerHTML = photo.ga_descripcion;
  }

  return(
    <>
      {agencies.map( (agency, index) => (
        <article key={agency.ag_id} id={`modal-gallery${agency.ag_id}`} className="modal modal-hide">
          <div className="container modal-box columns pb-16">
            <div className="modal-btn-close col-sm-12">
              <i className="fas fa-times" onClick={ () => { toggleModal(`modal-gallery${agency.ag_id}`) } }></i>
            </div>
            <div className="gallery-section columns col-sm-12">
                { (agency.galeria[0]) ?
                  <div className="col-sm-12 col-md-8">
                    <img id={`urlPhoto${agency.ag_id}`} src={ agency.galeria[0].ga_imagen } alt={ agency.galeria[0].ga_titulo } className="gallery-principal mb-8" />
                    <h3 id={`titlePhoto${agency.ag_id}`} className="bold text-24">{ agency.galeria[0].ga_titulo }</h3>
                    <p id={`descriptionPhoto${agency.ag_id}`}>{ agency.galeria[0].ga_descripcion }</p>
                  </div>
                  : null
                }
              <div className="gallery-column col-sm-12 col-md-4">
                <h3 className="bold text-24 mb-8">Ver más fotografías</h3>
                {agency.galeria.map( photo => (
                  <img key={ photo.ga_id } data-id={ photo.ga_id } data-index={ index } src={ photo.ga_imagen } alt={ photo.ga_titulo } className="gallery-img mb-8" onClick={ onGalleryChange } />
                ))}
              </div>
            </div>
          </div>
        </article>
      ))}
    </>
  )
}

export default ModalGallery;