import React from 'react';

const NotFound = () => {
  return(
    <div className="my-64 mx-32">
      <h2 className="text-48 bold">Página no encontrada</h2>
      <h3 className="text-24">Use el menú de navegación para acceder a otra sección</h3>
    </div>
  )
}

export default NotFound;