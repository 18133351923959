import React from 'react';
import ModalBannerForm from '../components/ModalBannerForm';
import ModalApplicationForm from '../components/ModalApplicationForm';
import ModalGallery from '../components/ModalGallery';
import ModalServices from '../components/ModalServices';


const Modals = ( { services, getService, activities, bannerTitle, agencies } ) => {
  return(
    <section id="modals-section">
      <ModalBannerForm bannerTitle={ bannerTitle } />
      <ModalApplicationForm services={ services } activities={ activities } />
      <ModalServices services={ services } getService={ getService } />
      <ModalGallery agencies={ agencies } />
    </section>
  )
}

export default Modals;