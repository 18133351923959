import React, { useState } from 'react';
import toggleModal from '../helpers/toggleModal';
import validateFormBanner from '../helpers/validateFormBanner';

const FormBanner = ( { bannerTitle } ) => {
  const today = new Date();
  today.setHours(0,0,0,0);
  const [ form, setForm ] = useState({
    pa_nombre: "",
    pa_apellidos: "",
    pa_dui: "",
    pa_telefono: "",
    pa_correo: "",
    pa_numero: "",
    pa_fecha: today.toISOString().slice(0,10),
    pa_promocion: bannerTitle
  });

  const handleChange = e => {
    setForm( {
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleInput = (e, type) => {
    const k = e.keyCode;
    if(type === 'number') {
      if(!((k >= 48 && k <= 57) || (k === 8) || (k === 9))) {
        e.preventDefault();
      }
    } else if(type === 'letter') {
      if(!((k >= 65 && k <= 90) || (k >= 97 && k <= 122) || (k === 32) || (k === 8) || (k === 9))) {
        e.preventDefault();
      }
    } else if(type === 'dui') {
      if(!((k >= 48 && k <= 57) || (k === 189) || (k === 8) || (k === 9))) {
        e.preventDefault();
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault();

    if(form.pa_dui === "") form.pa_dui = "00000000-0";
    validateFormBanner(form, document.querySelector('#btn-form-banner'))

    .then( res => {
      if(res) {
        restartForm();
        toggleModal('modal-banner-form')
      }
    })
  }

  const restartForm = e => {
    const inputs = document.querySelectorAll('.inputBanner');
    inputs.forEach( element => element.value = '' );

    setForm({
      pa_nombre: "",
      pa_apellidos: "",
      pa_dui: "",
      pa_telefono: "",
      pa_correo: "",
      pa_numero: "",
      pa_fecha: today.toISOString().slice(0,10),
      pa_promocion: bannerTitle
    })
  }

  return(
    <form className="form columns" onSubmit={ handleSubmit }>
      <label className="col-sm-12 text-center text-24 bold mb-24">Formulario para { bannerTitle } </label>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <input type="text" name="pa_nombre" placeholder="Nombres" className="inputBanner"
                onChange={ e => handleChange(e) } onKeyDown={ e => handleInput(e, 'letter') } />
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <input type="text" name="pa_apellidos" placeholder="Apellidos" className="inputBanner"
                onChange={ e => handleChange(e) } onKeyDown={ e => handleInput(e, 'letter') } />
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <input type="text" name="pa_dui" placeholder="DUI (incluir guión) (campo opcional)" className="inputBanner" maxLength="10"
                inputMode="numeric" onChange={ e => handleChange(e) } onKeyDown={ e => handleInput(e, 'dui') } />
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <input type="text" name="pa_telefono" placeholder="Teléfono (sin espacios ni guiones)" className="inputBanner" maxLength="8"
                inputMode="numeric" onChange={ e => handleChange(e) } onKeyDown={ e => handleInput(e, 'number') } />
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <input type="email" name="pa_correo" placeholder="Email" className="inputBanner"
                onChange={ e => handleChange(e) } />
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <input type="text" name="pa_numero" placeholder="Número de asociado (campo opcional)" className="inputBanner" maxLength="8"
                inputMode="numeric" onChange={ e => handleChange(e) } onKeyDown={ e => handleInput(e, 'number') } />
      </div>
      <button id="btn-form-banner" type="submit" className="center btn-primary">
        <i className="fas fa-paper-plane"></i><span className="bold ml-8">Enviar</span>
      </button>
    </form>
  )
}

export default FormBanner;