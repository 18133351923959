import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { PageContext } from '../helpers/pageContext';
import validateFormAssociate from '../helpers/validateFormAssociate'
import getDeparments from '../helpers/getDeparments';
import getMunicipalities from '../helpers/getMunicipalities';
import toggleModal from '../helpers/toggleModal';

const FormAssociate = ({ services, activities }) => {
  const {isFormAssociate} = useContext(PageContext);
  const location = useLocation();
  const today = new Date();
  today.setHours(0,0,0,0);
  const [ form, setForm ] = useState({
    cl_nombre: "",
    cl_apellido: "",
    cl_dui: "",
    cl_telefono: "",
    cl_correo: "",
    cl_edad: 0,
    cl_departamento: "",
    cl_municipio: "",
    cl_fecha: today.toISOString().slice(0,10),
    cl_interes: "",
    cl_estado: 1,
    cl_monto: 0,
    fk_actividad: "",
    fk_interes: ""
  });

  const handleChange = e => {
    setForm( {
      ...form,
      [e.target.name]: e.target.value,
    })

    if(e.target.id === 'slcDeparment1') {
      const select = document.querySelector('#slcMunicipality1');
      select.innerHTML = '';
      select.insertAdjacentHTML('beforeend', '<option value="">Municipio</option>');
      getMunicipalities(e.target.value).forEach( municipality => {
        select.insertAdjacentHTML('beforeend', `<option value="${municipality}">${municipality}</option>`);
      })
    }

    if(e.target.id === 'slcServices1') {
      const selectedService = services.find( service => service.it_id === e.target.value) || [];
      const select = document.querySelector('#slcServices2');
      select.innerHTML = '';
      select.insertAdjacentHTML('beforeend', '<option value="">Servicio específico</option>');
      if(selectedService.length !== 0) {
        selectedService.detalle_modal.forEach( specificService => {
          select.insertAdjacentHTML('beforeend', `<option value="${specificService.de_id}">${specificService.de_titulo}</option>`);
        })
      }
    }
  }

  const handleInput = (e, type) => {
    const k = e.keyCode;
    if(type === 'number') {
      if(!((k >= 48 && k <= 57) || (k === 8) || (k === 9))) {
        e.preventDefault();
      }
    } else if(type === 'letter') {
      if(!((k >= 65 && k <= 90) || (k >= 97 && k <= 122) || (k === 32) || (k === 8) || (k === 9))) {
        e.preventDefault();
      }
    } else if(type === 'dui') {
      if(!((k >= 48 && k <= 57) || (k === 189) || (k === 8) || (k === 9))) {
        e.preventDefault();
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault();

    if(form.cl_dui === "") form.cl_dui = "00000000-0";
    if(isFormAssociate) {
      delete form.cl_monto;
      delete form.cl_interes;
      delete form.fk_interes;
    }

    validateFormAssociate(form, document.querySelector('#btn-form-associate'))
    .then( res => {
      if(res) {
        restartForm();
        toggleModal('modal-reservation');
      }
    })
  }

  const restartForm = e => {
    const inputs = document.querySelectorAll('.inputAssociate');
    const selects = document.querySelectorAll('.selectAssociate');
    const checks = document.querySelectorAll('.checkAssociate');

    inputs.forEach( element => element.value = '' );
    selects.forEach( element => element.selectedIndex = 0 );
    checks.forEach( element => element.checked = false );
  }

  useEffect( () => {
    if(location.pathname === '/informacion') {
      toggleModal('modal-reservation')
    }
  }, [location.pathname])


  return(
    <form className="form columns" onSubmit={ e => handleSubmit(e) }>
      <label className="col-sm-12 text-center text-24 bold mb-24">Formulario de aplicación</label>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <input type="text" name="cl_nombre" placeholder="Nombres" className="inputAssociate"
          onChange={ e => handleChange(e) } onKeyDown={ e => handleInput(e, 'letter') } />
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <input type="text" name="cl_apellido" placeholder="Apellidos" className="inputAssociate"
          onChange={ e => handleChange(e) } onKeyDown={ e => handleInput(e, 'letter') } />
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <input type="text" name="cl_dui" placeholder="DUI (incluir guión) (campo opcional)" className="inputAssociate" maxLength="10"
          inputMode="numeric" onChange={ e => handleChange(e) } onKeyDown={ e => handleInput(e, 'dui') } />
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <input type="text" name="cl_telefono" placeholder="Teléfono (sin espacios ni guiones)" className="inputAssociate" maxLength="8"
          inputMode="numeric" onChange={ e => handleChange(e) } onKeyDown={ e => handleInput(e, 'number') } />
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <input type="email" name="cl_correo" placeholder="Email" className="inputAssociate"
          onChange={ e => handleChange(e) } />
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <input type="text" id="dpAge1" name="cl_edad" placeholder="Edad" min="1" max="99" className="inputAssociate" maxLength="2"
          inputMode="numeric" onChange={ e => handleChange(e) } onKeyDown={ e => handleInput(e, 'number') } />
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <select id="slcDeparment1" name="cl_departamento" className="selectAssociate" onChange={ e => handleChange(e) }>
          <option value="">Departamento</option>
          {getDeparments().map( (department) => ( <option key={department} value={department}>{department}</option> ) )}
        </select>
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8" >
        <select id="slcMunicipality1" name="cl_municipio" className="selectAssociate" onChange={ e => handleChange(e) }>
          <option value="">Municipio</option>
        </select>
      </div>
      <div className="col-sm-12 col-md-6 form-input py-16 px-8">
        <select id="slcActivity1" name="fk_actividad" className="selectAssociate" onChange={ e => handleChange(e) }>
          <option value="">Actividad económica</option>
          {activities.map((activity) => ( <option key={activity.ac_id} value={activity.ac_id}>{activity.ac_nombre}</option> ))}
        </select>
      </div>
      { (!isFormAssociate) ?
      <>
        <div className="col-sm-12 col-md-6 form-input py-16 px-8">
          <select id="slcServices1" name="cl_interes" className="selectAssociate" onChange={ e => handleChange(e) }>
            <option value="">Servicio de interés</option>
            {services.map((service) => ( <option key={service.it_id} value={service.it_id}>{service.it_titulo}</option> ))}
          </select>
        </div>
        <div className="col-sm-12 col-md-6 form-input py-16 px-8" >
          <select id="slcServices2" name="fk_interes" className="selectAssociate" onChange={ e => handleChange(e) }>
            <option value="">Servicio específico</option>
          </select>
        </div>
        <div className="col-sm-12 col-md-6 form-input py-16 px-8">
          <input type="text" id="dpAmount1" name="cl_monto" placeholder="Monto ($)" className="inputAssociate"
            inputMode="numeric" onChange={ e => handleChange(e) } onKeyDown={ e => handleInput(e, 'number') } />
        </div>
      </>
      : null }
      <button id="btn-form-associate" type="submit" className="center btn-primary col-md-3" >
        <div className="center">
          <i className="fas fa-paper-plane"></i><span className="bold ml-8">Enviar</span>
        </div>
      </button>
    </form>
  )
}

export default FormAssociate;